import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import { Avatar, CardHeader } from "@material-ui/core";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Select,
	InputLabel,
	MenuItem,
	FormControl,
	TextField,
	InputAdornment,
	IconButton
  } from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import useWhatsApps from "../../hooks/useWhatsApps";
import Globais from "../../services/globais.js";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email").required("Required"),
});

const TicketUserModal = ({ open, onClose, userId, userName, login, ticketId }) => {
	const classes = useStyles();
	const history = useHistory();

	const initialState = {
		name: "",
		email: "",
		password: "",
		profile: "user"
	};
	const setContactId = Globais.contactId;
	const { userInfo } = useContext(AuthContext);

	const [user, setUser] = useState(initialState);
	const [loading, setLoading] = useState(false);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [showPassword, setShowPassword] = useState(false);
	const [whatsappId, setWhatsappId] = useState(false);
	const [showTicketUser, setshowTicketUser] = useState(false);
	const [userIsAdmin, setUserIsAdmin] = useState(false);


	const handleClose = () => {
		onClose();
		setUser(initialState);
	};
	const handleOpenTicket = () =>{
		if(userId === userInfo.id){
			history.push('/tickets/'+ticketId)
		}else{
			console.log('Usuário ID '+userInfo.id+' não tem permissão para acessar esse ticket 1: '+userId)
		}

	}

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{userId
						? `Existe um Ticket aberto para esse contato`
						: `Existe um Ticket aberto para esse contato`}
				</DialogTitle>


							<DialogContent dividers>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
									{"Usuário em que o Ticket está atribuído: "}
								</Typography>
								<CardHeader
									style={{ cursor: "pointer" }}
									titleTypographyProps={{ noWrap: true }}
									subheaderTypographyProps={{ noWrap: true }}
									avatar={<Avatar src="/*https://www.megatekbrasil.com.br/assets/images/avatar.png*/" alt="contact_image" />}
									title={`${userName}`}
									subheader={
										`Login: ${login}`
									}

								/>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
									{"Favor entrar em contato com esse usuário e pedir ao mesmo para finalizar o Atendimento com esse contato."}
								</Typography>
							</DialogContent>
							<DialogActions>
								<Button
									type="submit"
									color="primary"
									variant="contained"
									className={classes.btnWrapper}
									onClick={() => handleClose()}
								>
									{userId
										? `Fechar`
										: `Fechar`}
								</Button>

								<Button
									type="submit"
									color="primary"
									variant="contained"
									className={classes.btnWrapper}
									onClick={() => handleOpenTicket()}
								> Atendimento{ticketId}
								</Button>

							</DialogActions>


			</Dialog>
		</div>
	);
};

export default TicketUserModal;
