import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormControlLabel, Checkbox, Switch } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import Globais from "../../services/globais"
import FormControl from "@material-ui/core/FormControl";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Can } from "../Can";
import Input from "@material-ui/core/Input";
import {ContactsFilter} from "../ContactsFilter";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	imageInput: {
		color: theme.palette.primary.main,
		marginLeft: 6,
		marginRight: 6,
		alignSelf: "center",
	},
}));

const ContactSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email"),
});

const ContactModal = ({ open, onClose, contactId,ticketId, initialValues, onSave, ...props }) => {
	const classes = useStyles();
	const isMounted = useRef(true);
	const [linkedUserId, setLinkedUserId] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [phone, setMask] = useState("(99) 99999-9999");
	const [file, setFile] = useState()
	const [isGroup, setIsgroup] = useState(false)
	const { user } = useContext(AuthContext);

	const initialState = {
		name: "",
		number: "",
		email: "",
	};


	const [contact, setContact] = useState(initialState);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {



		const fetchContact = async () => {
			if (initialValues) {
				setContact(prevState => {
					return { ...prevState, ...initialValues };
				});
			}

			if (!contactId) return;

			try {
				const { data } = await api.get(`/contacts/${contactId}`);
				if (isMounted.current) {
					if(data.linked_userId > 0 || Globais.vinculation_Default==="true"){
						setLinkedUserId(true)
					}else{
						setLinkedUserId(false)
					}

					setContact(data);
				}
				if(data.isGroup){
					setIsgroup(true)
				}else{
					setIsgroup(false)
				}
				console.log('Dados do usu�rio editado: ',data)
			} catch (err) {
				toastError(err);
			}
		};

		fetchContact();



	}, [contactId, open, initialValues]);

	const handleClose = () => {
		onClose();
		setContact(initialState);
	};

	const handleChange = (e) => {
		if (e.target.value === "linked_userId") {
			console.log('Salvando o contacts value: '+e.target.checked)
			setLinkedUserId(e.target.checked);
		}
	};

	const handleSaveContact = async values => {
		try {
			//Salvando os dados no contato do usu�rio que cadastrou e vinculando o contato ao usu�rio do sistema
			//A pedido do Flavio ADMIN ZAP
			if(linkedUserId){
				values.linked_userId = user.id;
			}else{
				values.linked_userId = 0;
			}
			values.number = values.number.replace("+", "").replace("-", "").replace("(", "").replace(")", "").replace(" ", "");

			if (contactId) {
				//Implementando alterar Nome e Foto de um contato que seja Grupo
				if(contact.isGroup){
					try {
						let data = {};

						const formData = new FormData()
						formData.append("medias", file)
						formData.append("name", values.name)
						formData.append("id", contactId)
						formData.append("userid", user.id)
						formData.append("ticketId", ticketId)
						formData.append("whatsappId", ticketId)
						formData.append("arrayUsers", selectedUser)

						await api.post(`/group/update`, formData, { headers: {'Content-Type': 'multipart/form-data'}});
						//	history.push(`/tickets/${ticketId}`);
						//	window.location.reload(true);

						//Implementado para verificar se o bot�o existe. Ideal para o Grupo ser editado pelo menu contato onde n�o tem o bot�o
						// Evitar erros de getElementById not found.
						const buttonClick = document.getElementById('getMembers');
						if(document.getElementById('getMembers')){
							buttonClick.click()
						}
						handleClose();
					} catch (err) {
						toastError(err);
					}
				}else {
					values.userId = user.id;
					await api.put(`/contacts/${contactId}`, values);
					window.location.reload(true);
				}
				handleClose();
			} else {
				const { data } = await api.post("/contacts", values);
				if (onSave) {
					onSave(data);
				}
				handleClose();
			}
			toast.success(i18n.t("contactModal.success"));
		} catch (err) {
			toastError(err);
		}
	};

	const handleSelectedTags = (selecteds) => {
		const tags = selecteds.map(t => t.id);
		setSelectedUser(tags);
	}


	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="xs" scroll="paper">
				<DialogTitle id="form-dialog-title">
					{contactId
						? `${i18n.t("contactModal.title.edit")}`
						: `${i18n.t("contactModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={contact}
					enableReinitialize={true}
					validationSchema={ContactSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveContact(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, errors, touched, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<Typography variant="subtitle1" gutterBottom>
									{i18n.t("contactModal.form.mainInfo")}
								</Typography>
								<Field
									as={TextField}
									label={i18n.t("contactModal.form.name")}
									name="name"
									autoFocus
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									variant="outlined"
									margin="dense"
									fullWidth
								/>
								{isGroup && (
									<Typography variant="subtitle1" gutterBottom>
										Alterar Foto do Grupo
									</Typography>
								)}

								<Can
									role={user.profile}
									perform="ticket-options:transferWhatsapp"
									yes={() => (isGroup &&

										<Input
											fullWidth
											type="file"
											accept="image/*"
											className={classes.imageInput}
											filename={file}
											onChange={e => setFile(e.target.files[0])}
											placeholder="Imagem do Grupo"
										/>

									)}
								/>
								{/*<Field
									as={TextField}
									label={i18n.t("contactModal.form.number")}
									name="number"
									error={touched.number && Boolean(errors.number)}
									helperText={touched.number && errors.number}
									placeholder="5513912344321"
									variant="outlined"
									margin="dense"
								/>*/}
								{!contact.isGroup && (<PhoneInput
									country={'br'}
									value={values.number}
									onChange={phone => values.number = phone}
									fullWidth
								/>)}

								{(isGroup &&
									<FormControl variant="outlined" fullWidth style={{ marginTop: 20 }}>
										<ContactsFilter onFiltered={handleSelectedTags} />
									</FormControl>
								)}

								{!contact.isGroup && ( <div>
										<Field
											as={TextField}
											label={i18n.t("contactModal.form.email")}
											name="email"
											error={touched.email && Boolean(errors.email)}
											helperText={touched.email && errors.email}
											placeholder="Email address"
											fullWidth
											margin="dense"
											variant="outlined"
										/>
									</div>
								)}
								{(Globais.vinculation_Default==="true" && contactId === null && user.profile.toUpperCase() !== "USER") && (
									<FormControlLabel
										value="linked_userId"
										control={
											<Field
												as={Switch}
												color="primary"
												name="linked_userId"
												checked={true}
												onChange={handleChange}
											/>
										}
										label={"Vinculado ao login"}
									/>
								)
								}
								{(Globais.vinculation_Default==="true" && contactId != null && user.profile.toUpperCase() !== "USER") && (
									<FormControlLabel
										value="linked_userId"
										control={
											<Field
												as={Switch}
												color="primary"
												name="linked_userId"
												checked={linkedUserId}
												onChange={handleChange}
											/>
										}
										label={"Vinculado ao login"}
									/>
								)
								}
								{(Globais.vinculation_Default==="false" && contactId === null && user.profile.toUpperCase() !== "USER") && (
									<FormControlLabel
										value="linked_userId"
										control={
											<Field
												as={Switch}
												color="primary"
												name="linked_userId"
												checked={false}
												onChange={handleChange}
											/>
										}
										label={"Vinculado ao login"}
									/>
								)
								}
								{(Globais.vinculation_Default==="false" && contactId != null && user.profile.toUpperCase() !== "USER") && (
									<FormControlLabel
										value="linked_userId"
										control={
											<Field
												as={Switch}
												color="primary"
												name="linked_userId"
												checked={linkedUserId}
												onChange={handleChange}
											/>
										}
										label={"Vinculado ao login"}
									/>
								)
								}
								{!isGroup && (
									<Typography
										style={{ marginBottom: 8, marginTop: 12 }}
										variant="subtitle1"
									>
										{i18n.t("contactModal.form.extraInfo")}
									</Typography>
								)}

								{!isGroup && ( <FieldArray name="extraInfo">
									{({ push, remove }) => (
										<>
											{values.extraInfo &&
												values.extraInfo.length > 0 &&
												values.extraInfo.map((info, index) => (
													<div
														className={classes.extraAttr}
														key={`${index}-info`}
													>
														<Field
															as={TextField}
															label={i18n.t("contactModal.form.extraName")}
															name={`extraInfo[${index}].name`}
															variant="outlined"
															margin="dense"
															className={classes.textField}
														/>
														<Field
															as={TextField}
															label={i18n.t("contactModal.form.extraValue")}
															name={`extraInfo[${index}].value`}
															variant="outlined"
															margin="dense"
															className={classes.textField}
														/>
														<IconButton
															size="small"
															onClick={() => remove(index)}
														>
															<DeleteOutlineIcon />
														</IconButton>
													</div>
												))}
											<div className={classes.extraAttr}>
												<Button
													style={{ flex: 1, marginTop: 8 }}
													variant="outlined"
													color="primary"
													onClick={() => push({ name: "", value: "" })}
												>
													{`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
												</Button>
											</div>
										</>
									)}
								</FieldArray> )}
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("contactModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{contactId
										? `${i18n.t("contactModal.buttons.okEdit")}`
										: `${i18n.t("contactModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ContactModal;
