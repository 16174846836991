import React, { useState, useContext } from "react";

import MenuItem from "@material-ui/core/MenuItem";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import { Menu } from "@material-ui/core";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import ContactListShareModal from "../ContactListShareModal";

const MessageOptionsMenu = ({ message, menuOpen, handleClose, anchorEl }) => {
  const { setReplyingMessage } = useContext(ReplyMessageContext);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isShareContactModalOpen, setIsShareContactModalOpen] = useState(false);
  const [sharedContacts, setSharedContacts] = useState([]);


  const handleDeleteMessage = async () => {
    try {
      await api.delete(`/messages/${message.id}`);
    } catch (err) {
      toastError(err);
    }
  };

  const hanldeReplyMessage = () => {
    setReplyingMessage(message);
    handleClose();
  };

  const handleOpenConfirmationModal = (e) => {
    setConfirmationOpen(true);
    handleClose();
  };

  const hanldeForwardMessageClick = () => {
    setIsShareContactModalOpen(true);
  };

 /* const hanldeForwardMessage = async (contacts) => {
    try {
      const forwardData = {
        contactId: null, // ID do contato para o qual está encaminhando a mensagem, se aplicável
        messageId: message.id,
        number: "", // número do contato para o qual está encaminhando a mensagem
        isGroup: false // Definir true se estiver encaminhando para um grupo, caso contrário, deixar como false
      };
  
      await api.post(`/messages/forward/${message.ticketId}`, forwardData);
      setIsShareContactModalOpen(false);
    } catch (err) {
      toastError(err);
    }
  }; */

  const hanldeForwardMessage = async (contacts) => {
    try {
      // Mapeia cada contato e envia uma solicitação para o backend para encaminhar a mensagem
      await Promise.all(contacts.map(async (contact) => {
        const forwardData = {
          contactId: contact.id, // ID do contato para o qual está encaminhando a mensagem
          messageId: message.id,
          number: contact.number, // número do contato para o qual está encaminhando a mensagem
          isGroup: contact.isGroup // Definir true se estiver encaminhando para um grupo
        };
  
        await api.post(`/messages/forward/${message.ticketId}`, forwardData);
      }));
  
      // Fecha o modal após o encaminhamento de todas as mensagens
      setIsShareContactModalOpen(false);
      setSharedContacts([]); // limpa a lista de contatos.
    } catch (err) {
      toastError(err);
    }
  };
  
  

  return (
    <>
      <ConfirmationModal
        title={i18n.t("messageOptionsMenu.confirmationModal.title")}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={handleDeleteMessage}
      >
        {i18n.t("messageOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>

      {isShareContactModalOpen && (
        <ContactListShareModal
        onSelectContacts={hanldeForwardMessage}
       onClose={() => setIsShareContactModalOpen(false)} 
      />
      )}


      {!isShareContactModalOpen && (
        <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        <MenuItem onClick={hanldeForwardMessageClick}>
          {"Encaminhar"}
        </MenuItem>
        {message.fromMe && (
          <MenuItem onClick={handleOpenConfirmationModal}>
            {i18n.t("messageOptionsMenu.delete")}
          </MenuItem>
        )}
        <MenuItem onClick={hanldeReplyMessage}>
          {i18n.t("messageOptionsMenu.reply")}
        </MenuItem>
      </Menu>)}
    </>
  );
};

export default MessageOptionsMenu;
