import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  calendarIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const DatePicker = ({ label, value, onChange }) => {
  const classes = useStyles();

  const handleDateChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={classes.root}>
      <TextField
        label={label}
        type="date"
        value={value}
        onChange={handleDateChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );
};

export default DatePicker;

