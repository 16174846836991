import React, { useRef } from "react";
import { Modal } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DescriptionIcon from "@material-ui/icons/Description";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";

const attachmentModalStyle = {
  container: {
    position: "absolute",
    left: "50vw",
    bottom: "calc(0px - (-20px))",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "6px",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
  },

  columnContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "6px",
    alignItems: "center",
  },

  button: {
    position: "relative",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "background-color 0.3s",
    textDecoration: "none",
    outline: "none",
    border: "none",
    position: "relative",
    overflow: "hidden",
  },
  topHalf: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "50%",
    backgroundColor: "#5157AE",
    borderRadius: "50% 50% 0 0",
  },
  bottomHalf: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "50%",
    backgroundColor: "#5F66CD",
    borderRadius: "0 0 50% 50%",
  },
  icon: {
    fontSize: 18,
    zIndex: 1,
    color: "white",
  },
  input: {
    position: "absolute",
    opacity: 0,
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    cursor: "pointer",
  },
};
const AttachmentModal = ({ open, onClose, onUpload, onLocationSelect, onOpenShareContactModal }) => {
  const fileInputRef = useRef(null);

  const handleFileInputChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    onUpload(selectedFiles);
  };

  const handleLocationSelect = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          onLocationSelect({ latitude, longitude });
        },
        (error) => {
          console.error("Erro ao obter a localização:", error);
        }
      );
    } else {
      console.error("Geolocalização não suportada pelo navegador.");
    }
  };

  const handleOpenShareContactModal = () => {
    // Chamar a função de callback fornecida pelo pai
    if (onOpenShareContactModal) {
      onOpenShareContactModal();
      onClose()
    }
  };
  

  return (
    <Modal open={open} onClose={onClose} BackdropProps={{ style: { backgroundColor: 'transparent' } }}>
      <div style={attachmentModalStyle.container}>
        <div style={attachmentModalStyle.columnContainer}>
          <button
            style={attachmentModalStyle.button}
            onClick={() => fileInputRef.current.click()}
          >
            <div style={attachmentModalStyle.topHalf}></div>
            <div style={attachmentModalStyle.bottomHalf}></div>
            <DescriptionIcon style={attachmentModalStyle.icon} />
          </button>
          <span>Documento</span>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          style={attachmentModalStyle.input}
          onChange={handleFileInputChange}
        />

        <div style={attachmentModalStyle.columnContainer}>
          <button style={attachmentModalStyle.button} onClick={handleLocationSelect}>
            <div
              style={{
                ...attachmentModalStyle.topHalf,
                backgroundColor: "#018747",
              }}
            ></div>
            <div
              style={{
                ...attachmentModalStyle.bottomHalf,
                backgroundColor: "#029952",
              }}
            ></div>
            <LocationOnIcon style={attachmentModalStyle.icon} />
          </button>
          <span>Localização</span>
        </div>
        <div style={attachmentModalStyle.columnContainer}>
          <button style={attachmentModalStyle.button} onClick={handleOpenShareContactModal}>
            <div
              style={{
                ...attachmentModalStyle.topHalf,
                backgroundColor: "#0995DC",
              }}
            ></div>
            <div
              style={{
                ...attachmentModalStyle.bottomHalf,
                backgroundColor: "#09ABF4",
              }}
            ></div>
            <ContactPhoneIcon style={attachmentModalStyle.icon} />
          </button>
          <span>Contato</span>
        </div>
      </div>
    </Modal>
  );
};

export default AttachmentModal;
