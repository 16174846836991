export default class Globais{
    static usuario = ''
    static whatsappId = ''
    static showOnlyUserMessages = ''
    static forcedwhatsappId = ''
    static ticketId = ''
    static chatroomid = ''
    static hashmsg = ''
    static finishTicket = ''
    static contactid = ''
    static signMessage = ''
    static reloadpage = ''
    static viewPage = ""
    static vinculation_Default = ""
    static domainvoip = ''
    static urivoip = ''
    static passvoip = ''
    static websocketvoip = ''
    static linked_userId = ''
    static mode_anonymous = ''
    static hiddenMessagesUserId = ''
    static closeviewticket = ''
    static sendfinishticket = ''
    static notification_tickets = ''
    static searchParam = null
    static array_ids = []
    static require_users = 0
    static anonymous = 'false'

}