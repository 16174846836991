import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: 2,
  },
  selectedItem: {
    backgroundColor: theme.palette.action.selected,
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  colorBox: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
  }
}));

const TagSelect = ({ selectedTagIds, onChange, title }) => {
  const classes = useStyles();
  const [tags, setTags] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(`/tags/list`);
        setTags(data);
        console.log(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div style={{ marginTop: 6 }}>
      <FormControl fullWidth margin="dense" variant="outlined">
        {title ? (
          <InputLabel>{title}</InputLabel>
        ) : (
          <InputLabel>{"Tags"}</InputLabel>
        )}
        <Select
          multiple
          value={selectedTagIds || []}
          onChange={handleChange}
          renderValue={(selected) => (
            <div>
              {selected.map((value) => {
                const selectedTag = tags.find((tag) => tag.id === value);
                return selectedTag ? (
                  <Chip
                    key={value}
                    style={{ backgroundColor: selectedTag.color }}
                    variant="outlined"
                    label={selectedTag.name}
                    className={classes.chip}
                  />
                ) : null;
              })}
            </div>
          )}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {tags.map((tag) => (
            <MenuItem
              key={tag.id}
              value={tag.id}
              className={
                selectedTagIds.includes(tag.id) ? classes.selectedItem : ""
              }
            >
              <div className={classes.menuItem}>
                <div className={classes.colorBox} style={{ backgroundColor: tag.color }}></div>
                {tag.name}
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default TagSelect;
