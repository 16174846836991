import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TableWithPagination from "../../components/TableWithPagination";
import api from "../../services/api";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Title from "../../components/Title";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Tooltip from "@material-ui/core/Tooltip";
import DatePicker from "../../components/DatePicker";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    display: "flex",
  },
  tableContainer: {
    backgroundColor: "#fff",
    padding: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  searchBar: {
    marginBottom: 0,
    marginTop: 0,
  },
  searchContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  truncatedText: {
    display: "inline-block",
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1),
  },
}));

const UserTicketsList = () => {
  const history = useHistory();
  const classes = useStyles();

  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ticketsData, setTicketsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [userName, setUserName] = useState("");
  const [filteredTickets, setFilteredTickets] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    // obter os tickets do usuário
    const fetchUserTickets = async () => {
      try {
        const response = await api.get(`/reports/user-tickets/${id}`);
        const { userName, tickets } = response.data;

        const formattedTickets = tickets.map((ticket) => ({
          ...ticket,
          status: translateStatus(ticket.status),
          etiquetasFormatted: formatEtiquetas(ticket.etiquetas),
          ultimaAtualizacao: formatDate(ticket.ultimaAtualizacao),
          filasFormatted: formatFilas(ticket.fila),
          lastMessage: ticket.ultimaMensagem,
        }));

        const sortedTickets = formattedTickets
          .slice()
          .sort(
            (a, b) =>
              new Date(b.ultimaAtualizacao) - new Date(a.ultimaAtualizacao)
          );

        setUserName(userName); // Armazena o nome do usuário
        setTicketsData(sortedTickets);
        setFilteredTickets(sortedTickets); // Atualiza os tickets filtrados
      } catch (error) {
        console.error("Erro ao obter os tickets do usuário:", error);
      }
    };

    fetchUserTickets(); // Chama a função para obter os tickets do usuário ao montar o componente
  }, [id]); // O useEffect será chamado novamente sempre que o userId mudar

  useEffect(() => {
    setFilteredTickets(ticketsData); // Atualiza os tickets filtrados quando os dados originais são atualizados
  }, [ticketsData]);

  useEffect(() => {
    filterTickets();
  }, [searchQuery, startDate, endDate]);

  // Método para traduzir o status
  const translateStatus = (status) => {
    return status === "open" ? "Aberto" : "Fechado";
  };

  // Método para formatar as etiquetas
  const formatEtiquetas = (etiquetas) => {
    if (!etiquetas || etiquetas.length === 0) {
      return []; // Retorna um array vazio se não houver etiquetas
    }

    return etiquetas.map((etiqueta, index) => (
      <span
        key={index}
        style={{
          backgroundColor: etiqueta.cor,
          color: "white",
          padding: "2px 5px",
          borderRadius: "3px",
          marginRight: "5px",
        }}
      >
        {etiqueta.nome}
      </span>
    ));
  };

  // Método para formatar as filas
  const formatFilas = (fila) => {
    if (!fila) {
      return ""; // Retorna uma string vazia se não houver fila
    }

    return (
      <span
        style={{
          backgroundColor: fila.cor,
          color: "white",
          padding: "2px 5px",
          borderRadius: "3px",
          marginRight: "5px",
        }}
      >
        {fila.nome}
      </span>
    );
  };

  // Método para formatar a data
  const formatDate = (date) => {
    return format(new Date(date), "dd/MM/yyyy HH:mm");
  };

  // Método para filtrar os tickets com base na pesquisa, data inicial e data final
  const filterTickets = () => {
    let filtered = ticketsData;

    // Filtrar com base na pesquisa
    if (searchQuery.trim()) {
      const searchTerm = searchQuery.toLowerCase().trim();
      filtered = filtered.filter((ticket) => {
        if (ticket.id.toString().includes(searchTerm)) {
          return true;
        }

        if (
          ticket.fila &&
          ticket.fila.nome.toLowerCase().includes(searchTerm)
        ) {
          return true;
        }

        if (
          ticket.etiquetasFormatted.find((etiqueta) =>
            etiqueta.props.children.toLowerCase().includes(searchTerm)
          )
        ) {
          return true;
        }

        return false;
      });
    }

    // Filtrar com base na data inicial

    if (startDate && !endDate) {
      // Converter a data inicial para o formato de data completa usando Moment.js
      const startDateFull = moment(startDate, "YYYY-MM-DD");

      filtered = filtered.filter((ticket) => {
        if (
          moment(ticket.ultimaAtualizacao, "DD/MM/YYYY HH:mm").isSameOrAfter(
            startDateFull
          )
        ) {
          return true;
        }
      });
    }

    // Filtrar com base na data final
    if (endDate && !startDate) {
      const endDateFull = moment(endDate, "YYYY-MM-DD");

      filtered = filtered.filter((ticket) => {
        const ticketDate = moment(ticket.ultimaAtualizacao, "DD/MM/YYYY HH:mm");
        return ticketDate.isSameOrBefore(endDateFull, "day");
      });
    }

    // com base na data inicial e final (juntas)
    if (startDate && endDate) {
      const startDateFull = moment(startDate, "YYYY-MM-DD");
      const endDateFull = moment(endDate, "YYYY-MM-DD");

      filtered = filtered.filter((ticket) => {
        const ticketDate = moment(ticket.ultimaAtualizacao, "DD/MM/YYYY HH:mm");
        return ticketDate.isBetween(startDateFull, endDateFull, "day", "[]");
      });
    }

    setFilteredTickets(filtered); // Atualizar os tickets filtrados
    console.log("dados filtrados", filtered); // Adicione esta linha
    return filtered;
  };

  // Definindo as colunas da tabela
  const columns = [
    { field: "id", headerName: "ID", minWidth: 100 },
    { field: "status", headerName: "Status", minWidth: 100 },
    { field: "filasFormatted", headerName: "Fila", minWidth: 200 },
    { field: "etiquetasFormatted", headerName: "Etiquetas", minWidth: 200 },
    {
      field: "lastMessage",
      headerName: "Ulti. Mensagem",
      minWidth: 150,
      render: (value) => (
        <Tooltip title={value} classes={{ tooltip: classes.tooltip }}>
          <span className={classes.truncatedText}>{value}</span>
        </Tooltip>
      ),
    },
    {
      field: "ultimaAtualizacao",
      headerName: "Ulti. Atualização",
      minWidth: 150,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (row) => {
    history.push(`/tickets/${row.id}`);
  };

  const handleGoBack = () => {
    history.goBack(); // Função para voltar à página anterior
  };

  return (
    <div style={{ padding: "10px" }}>
      <Button onClick={handleGoBack}>Voltar</Button>
      <div className={classes.searchContainer}>
        <Title>Tickets por Usuário</Title>
        <MainHeaderButtonsWrapper>
          <div
            style={{ display: "flex", columnGap: "10px", alignItems: "center" }}
          >
            <DatePicker
              label="Data Inicial"
              value={startDate}
              onChange={setStartDate}
            />
            <DatePicker
              label="Data Final"
              value={endDate}
              onChange={setEndDate}
            />
            <TextField
              className={classes.searchBar}
              label="Pesquisar"
              placeholder="ID, Etiqueta, Fila"
              variant="outlined"
              type="search"
              size="small"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
            />{" "}
          </div>
        </MainHeaderButtonsWrapper>
      </div>
      <Paper className={classes.tableContainer}>
        <p>{userName}</p>

        <TableWithPagination
          columns={columns}
          data={filteredTickets} // Usar os tickets filtrados
          rowsPerPageOptions={[10, 25, 50]}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={filteredTickets.length} // Usar o comprimento dos tickets filtrados
          onRowClick={handleRowClick}
          customizeColumns={true} // Definindo para personalizar as colunas
        />
      </Paper>
    </div>
  );
};

export default UserTicketsList;
