import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import {
	BarChart,
	CartesianGrid,
	Bar,
	XAxis,
	YAxis,
	Label,
	ResponsiveContainer,
} from "recharts";
import { startOfHour, parseISO, format } from "date-fns";

import { i18n } from "../../translate/i18n";

import Title from "./Title";
import useTickets from "../../hooks/useTickets";

const ChartAtendente = ({tickets, loading}) => {
	const theme = useTheme();
	let users = [];
	let reduced = [];

	const [chartData, setChartData] = useState([
		{ time: "desconhecido", amount: 0 },
		{ time: "desconhecido1", amount: 0 },
	]);

	useEffect(() => {
		setChartData([
			{ time: "desconhecido", amount: 0 },
			{ time: "desconhecido1", amount: 0 },
		])
		setChartData(prevState => {
			let aux = [...prevState];

				if(tickets?.tickets?.length > 0) {
					tickets.tickets.forEach(ticket => {
						if(ticket.user?.name) {
							//Criando um array com os usuários dos tickets
							console.log('Array quebrado dos Tickets: ' + ticket.user.name)
							users.push({time:ticket.user?.name, amount:0 })
						}
					});
				}
			console.log('Dados do Array do Users: ',users)

			//Removendo itens duplicados do array users
			const parsed_array = users.map(val=>{return JSON.stringify(val)})
			const reduced = parsed_array.filter((value, ind)=> parsed_array.indexOf(value) == ind).map((val)=>{return JSON.parse(val)})


			console.log('Dados do Array reduzido do Users: ',reduced)

			reduced.forEach(a => {
				if(tickets?.tickets?.length > 0) {
					tickets.tickets.forEach(ticket => {
						if(ticket.user?.name) {
							//Criando um array com os usuários dos tickets
							console.log('Array quebrado user Name: ' + ticket.user.name)
							ticket.user?.name === a.time && a.amount++;
						}
					});
				}
			});

			if(reduced) {
				setChartData(reduced);
			}
			console.log('Dados Finais do Array dos Atendentes: ',chartData)
			return aux;
		});
	}, [tickets]);



	return ( !loading ?
			<React.Fragment>
			<Title>Atendimentos por Atendente</Title>
			<ResponsiveContainer>
				<BarChart
					data={chartData}
					barSize={30}
					width={730}
					height={250}
					margin={{
						top: 16,
						right: 16,
						bottom: 0,
						left: 24,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="time" stroke={theme.palette.text.secondary} />
					<YAxis
						type="number"
						allowDecimals={false}
						stroke={theme.palette.text.secondary}
					>
						<Label
							angle={270}
							position="left"
							style={{ textAnchor: "middle", fill: theme.palette.text.primary }}
						>
							Tickets
						</Label>
					</YAxis>
					<Bar dataKey="amount" fill={theme.palette.primary.main} />
				</BarChart>
			</ResponsiveContainer>
		</React.Fragment>
			: <Skeleton variant="rect" height={80} />
	);
};

export default ChartAtendente;
