import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: 2,
  },
  selectedItem: {
    backgroundColor: theme.palette.action.selected, // Background color for selected item
  },
}));

const DefaultQueueSelect = ({ selectedQueueId, onChange, title }) => {
  const classes = useStyles();
  const [queues, setQueues] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/queue");
        setQueues(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  const handleSelectClose = (e) => {
    if (selectedQueueId) {
      onChange("");
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value === selectedQueueId) {
      onChange("");
    } else {
      onChange(value);
    }
  };

  return (
    <div style={{ marginTop: 6 }}>
      <FormControl fullWidth margin="dense" variant="outlined">
        {title ? (
          <InputLabel>{title}</InputLabel>
        ) : (
          <InputLabel>{"Fila Padrão"}</InputLabel>
        )}
        <Select
          labelWidth={60}
          value={selectedQueueId || ""}
          onChange={handleChange}
          onClose={handleSelectClose}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          renderValue={(selected) => {
            const selectedQueue = queues.find(
              (queue) => queue.id === selectedQueueId
            );
            return selectedQueue ? (
              <Chip
                style={{ backgroundColor: selectedQueue.color }}
                variant="outlined"
                label={selectedQueue.name}
                className={classes.chip}
              />
            ) : (
              ""
            );
          }}
        >
          {queues.map((queue) => (
            <MenuItem
              key={queue.id}
              value={queue.id}
              className={
                selectedQueueId === queue.id ? classes.selectedItem : ""
              }
            >
              {queue.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default DefaultQueueSelect;
