import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import ModalImage from "react-modal-image";
import Typography from "@material-ui/core/Typography";
import {Button} from "@material-ui/core";
import api from "../../services/api";
import {GetApp} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
	messageMedia: {
		objectFit: "cover",
		width: 90,
		height: 90,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
	},
}));

let formattedToday;
const ModalImageDetails = ({ imageUrl, imageName, imageDate }) => {
	const classes = useStyles();
	const [fetching, setFetching] = useState(true);
	const [blobUrl, setBlobUrl] = useState("");
	const [isTypeImage, setIsTypeImage] = useState(true); //Vem como tipo imagem por padrão
	const [isTypePDF, setIsTypePDF] = useState(false);
	const [isTypeDOC, setIsTypeDOC] = useState(false);
	const [isTypeMP4, setIsTypeMP4] = useState(false);

	useEffect(() => {
		var typePDF = imageUrl.indexOf('.pdf');
		var typeDOC = imageUrl.indexOf('.doc');
		var typeMP4 = imageUrl.indexOf('.mp4');

		/*const yyyy = imageDate.getFullYear();
		let mm = imageDate.getMonth() + 1; // Months start at 0!
		let dd = imageDate.getDate();

		if (dd < 10) dd = '0' + dd;
		if (mm < 10) mm = '0' + mm;*/

		formattedToday = imageDate.slice(0,10).split('-').reverse().join('/')

		if (typePDF != -1){
			console.log('Tipo do arquivo é um PDF')
			setIsTypeImage(false)
			setIsTypePDF(true)

		}
		if (typeDOC != -1){
			console.log('Tipo do arquivo é um DOC')
			setIsTypeImage(false)
			setIsTypeDOC(true)
		}
		if (typeMP4 != -1){
			console.log('Tipo do arquivo é um DOC')
			setIsTypeImage(false)
			setIsTypeMP4(true)
		}

		if (!imageUrl) return;
		const fetchImage = async () => {
			const { data, headers } = await api.get(imageUrl, {
				responseType: "blob",
			});
			const url = window.URL.createObjectURL(
				new Blob([data], { type: headers["content-type"] })
			);
			setBlobUrl(url);
			setFetching(false);
		};
		fetchImage();
	}, [imageUrl]);

	return (
		<>
			{isTypeImage &&(
				<div style={{position:"relative", height:200, background:"#dadada", margin:3}}>
					<div style={{display:"table-cell", paddingTop:10, paddingLeft:10}}>
						<ModalImage
							className={classes.messageMedia}
							smallSrcSet={fetching ? imageUrl : blobUrl}
							medium={fetching ? imageUrl : blobUrl}
							large={fetching ? imageUrl : blobUrl}
							showRotate="true"
							alt="image"

						/>
					</div>
					<div style={{display:"table-cell", verticalAlign:"top", padding:10}}>
						<div>
							<Typography style={{fontSize:12}}>Imagem recebida</Typography>
						</div>
						<div>
							<Typography style={{fontSize:12}}>Data: {formattedToday}</Typography>
						</div>
					</div>
				</div>
			)}
			{isTypePDF &&(
				<div style={{position:"relative", height:200, background:"#dadada", margin:3}}>
					<div style={{display:"table-cell", paddingTop:10, paddingLeft:10}}>
						<img src={'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg'} style={{width:90,height:90}}/>
					</div>
					<div style={{display:"table-cell", verticalAlign:"top", padding:10}}>
						<div>
						<Typography style={{fontSize:12}}>Nome do Arquivo: {imageName}</Typography>
						</div>
						<div>
							<Typography style={{fontSize:12}}>Data: {formattedToday}</Typography>
						</div>
						<Button
							startIcon={<GetApp />}
							color="primary"
							variant="outlined"
							target="_blank"
							href={imageUrl}
						>
							Download
						</Button>
					</div>
				</div>
			)}
			{isTypeDOC &&(
				<div style={{position:"relative", height:200, background:"#dadada", margin:3}}>
					<div style={{display:"table-cell", paddingTop:10, paddingLeft:10}}>
						<img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/.docx_icon.svg/240px-.docx_icon.svg.png'} style={{width:90,height:90}}/>
					</div>
					<div style={{display:"table-cell", verticalAlign:"top", padding:10}}>
						<div>
							<Typography style={{fontSize:12}}>Nome do Arquivo: {imageName}</Typography>
						</div>
						<div>
							<Typography style={{fontSize:12}}>Data: {formattedToday}</Typography>
						</div>
						<Button
							startIcon={<GetApp />}
							color="primary"
							variant="outlined"
							target="_blank"
							href={imageUrl}
						>
							Download
						</Button>
					</div>
				</div>
			)}
			{isTypeMP4 &&(
				<div style={{position:"relative", height:200, background:"#dadada", margin:3}}>
					<div style={{display:"table-cell", paddingTop:10, paddingLeft:10}}>
						<video
							className={classes.messageMedia}
							src={imageUrl}
							controls
						/>
					</div>
					<div style={{display:"table-cell", verticalAlign:"top", padding:10}}>
						<div>
							<Typography style={{fontSize:12}}>Vídeo Recebido</Typography>
						</div>
						<div>
							<Typography style={{fontSize:12}}>Data: {formattedToday}</Typography>
						</div>
					</div>
				</div>
			)}
		</>

	);
};

export default ModalImageDetails;
