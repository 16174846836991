import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import QueueSelect from "../QueueSelect";
import WhatsAppSelect from "../WhatsappSelect";
import {Can} from "../Can";
import Globais from "../../services/globais"
import TicketUserModal from "../TicketUserModal";


const filter = createFilterOptions({
	trim: true,
});

const NewTicketGroupModal = ({ modalOpen, onClose, iddoticket, contactId }) => {
	const history = useHistory();

	const initialState = {
		name: "",
		email: "",
		password: "",
		profile: "user"
	};
	//const { user: loggedInUser } = useContext(AuthContext);
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedContact, setSelectedContact] = useState(null);
	const [newContact, setNewContact] = useState({});
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const { user } = useContext(AuthContext);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [selectedwhatsappIds, setSelectedwhatsappIds] = useState([]);
	const [user2, setUser] = useState(initialState);
	const [showTicketUser, setshowTicketUser] = useState(false);
	const [userName, setUserName] = useState('');
	const [loginUser, setLoginUser] = useState('');
	const [ticketId, setTicketId] = useState('');

	useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("contacts", {
						params: { searchParam },
					});
					setOptions(data.contacts);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen]);

	useEffect(() => {
		const fetchUser = async () => {
			if (!user.id || Globais.require_users === 0) return;
			try {
				const { data } = await api.get(`/users/${user.id}`);
				console.log('Requisitando o users...')
				setUser(prevState => {
					return { ...prevState, ...data };
				});
				const userQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(userQueueIds);
				const userWhatsappIds = data.whatsapp?.whatsapp?.map(wa => wa.id);
				console.log('Retorno dos dados do usu�rio (userWhatsappIds): ',userWhatsappIds)
				setSelectedwhatsappIds(userWhatsappIds);
				Globais.require_users = 1
				//setWhatsappId(data.whatsappId ? data.whatsappId : '');
			} catch (err) {
				toastError(err);
			}
		};

		fetchUser();
	}, [user.id]);

	const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedContact(null);
	};

	const handleSaveTicket = async () => {
		//Implementado para abrir um ticket com o ID do Ticket vindo do aceitar ticket do Grupo
		if (iddoticket) {
			setLoading(true);
			console.log('Criando ticket com o com a fila: ' + selectedQueueIds[0] + ' e o Ticket: ID' + iddoticket)
			if (!selectedQueueIds) {
				toastError('Nenhum setor foi selecionado!')
				return;
			}
			if (selectedQueueIds <= 0) {
				toastError('Nenhum setor foi selecionado!')
				return;
			}
			try {
				const envio = await api.put(`/tickets/${iddoticket}`, {
					//contactId: contactId,
					userId: user.id,
					queueId: selectedQueueIds[0],
					status: "open",
				});
				history.push(`/tickets/${iddoticket}`);
			} catch (err) {
				toastError(err);
			}
			setLoading(false);
			handleClose();

		}
		if (contactId) {
			//Implementado para abrir um ticket com o ID do contato vindo do Grupo
			setLoading(true);
			console.log('Criando ticket com o com a fila: ' + selectedQueueIds[0] + ' e o Ticket: ID' + iddoticket)
			if (!selectedQueueIds) {
				toastError('Nenhum setor foi selecionado!')
				return;
			}
			if (selectedQueueIds <= 0) {
				toastError('Nenhum setor foi selecionado!')
				return;
			}
			try {
				const {data: ticket} = await api.post("/tickets", {
					contactId: contactId,
					userId: user.id,
					queueId: selectedQueueIds,
					status: "open",
					whatsappId: selectedwhatsappIds
				});
				history.push(`/tickets/${ticket.id}`);
			} catch (err) {
				const errorMsg = err.response?.data?.message || err.response.data.error;
				if (errorMsg) {
					const ticketError = errorMsg.split("|")
					setTicketId(ticketError[1])
					setUserName(ticketError[2])
					setLoginUser(ticketError[3])
					setshowTicketUser(true);

				}
				//toastError(err);
			}
			setLoading(false);
			handleClose();
		}
	}

	const handleSelectOption = (e, newValue) => {
		if (newValue?.number) {
			setSelectedContact(newValue);
		} else if (newValue?.name) {
			setNewContact({ name: newValue.name });
			setContactModalOpen(true);
		}
	};

	const handleCloseContactModal = () => {
		setContactModalOpen(false);
	};

		const handleCloseQueueModal = async (contactId) => {
			setshowTicketUser(false);
		};

	const handleAddNewContactTicket = contact => {
		handleSaveTicket(contact.id);
	};

	const createAddContactOption = (filterOptions, params) => {
		const filtered = filter(filterOptions, params);

		if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
			filtered.push({
				name: `${params.inputValue}`,
			});
		}

		return filtered;
	};

	const renderOption = option => {
		if (option.number) {
			return `${option.name} - ${option.number}`;
		} else {
			return `${i18n.t("newTicketModal.add")} ${option.name}`;
		}
	};

	const renderOptionLabel = option => {
		if (option.number) {
			return `${option.name} - ${option.number}`;
		} else {
			return `${option.name}`;
		}
	};

	return (
		<>
			<ContactModal
				open={contactModalOpen}
				initialValues={newContact}
				onClose={handleCloseContactModal}
				onSave={handleAddNewContactTicket}
			></ContactModal>
			<TicketUserModal
				open={showTicketUser}
				onClose={handleCloseQueueModal}
				aria-labelledby="form-dialog-title"
				userName={userName}
				login={loginUser}
			/>
			<Dialog open={modalOpen} onClose={handleClose}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("newTicketGroupModal.title")}
				</DialogTitle>
				<DialogContent dividers>
					<Can
						role={user.profile}
						perform="ticket-modal:showQueues"
						yes={() => (
							<QueueSelect
								selectedQueueIds={selectedQueueIds}
								onChange={values => setSelectedQueueIds(values)}
							/>
						)}
					/>
					<Can
						role={user.profile}
						perform="ticket-modal:editQueues"
						yes={() => (
							<WhatsAppSelect
								selectedQueueIds={selectedwhatsappIds}
								onChange={values => setSelectedwhatsappIds(values)}
								title={'Conex�es'}
							/>
						)}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("newTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="button"
						//disabled={!selectedContact}
						onClick={() => handleSaveTicket()}
						color="primary"
						loading={loading}
					>
						{i18n.t("newTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default NewTicketGroupModal;

