import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Field, Form, Formik } from "formik";
import LinearBuffer from "../../components/LinearBuffer";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import PreviewMessage from "../../components/PreviewMessage";
import SelectConection from "../../components/SelectConection";
import TagSelect from "../../components/TagSelect";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { CSVLink } from "react-csv";
import { Tooltip } from "@material-ui/core";
import { ImportContacts } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnWrapper: {
    position: "relative",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  buttonProgress: {
    color: "green[500]",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ContactSchema = Yup.object().shape({
  phone: Yup.string(),
  model: Yup.string(), // Não obrigatório
  image: Yup.mixed(), // Não obrigatório
});

const Contacts = () => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const [selectedQueueIds, setSelectedQueueIds] = useState();
  const [progressMessage, setProgressMessage] = useState(0);
  const [message, setMessage] = useState([]);
  const [phone, setPhone] = useState("");
  const [phoneData, setPhoneData] = useState("");
  const [image, setImage] = useState(null);
  const [csvData, setCSVData] = useState([]);

  const [selectedTagIds, setSelectedTagIds] = useState([]);

  const initialState = {
    phone: "",
    model: "",
    image: null,
    whatsappId: "",
  };

  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const [contact, setContact] = useState(initialState);

  const templateData = [
    { name: "Dinamica", number: "5511922258800", email: "" }, // Dados para o template csv
  ];

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    console.log("Valor de phone após atualização:", phone);
  }, [phone]);

  const handleClose = () => {
    setContact(initialState);
    setProgressMessage(0);
  };

  const handleSaveContact = async (values, actions) => {
    try {
      console.log(selectedTagIds)
      if (!values.phone && selectedTagIds.length == 0) {
        toast.error("O campo telefone é obrigatório");
        actions.setSubmitting(false);
        return;
      }

      if (!values.model && !values.image) {
        toast.error(
          "É necessário preencher pelo menos um dos campos: Modelo ou Imagem"
        );
        actions.setSubmitting(false);
        return;
      }

      if (!selectedQueueIds) {
        toast.error("Selecione uma conexão");
        actions.setSubmitting(false);
        return;
      }


      const whatsappId = parseInt(selectedQueueIds);

      const formData = new FormData();
      formData.append("phone", values.phone);
      formData.append("message", values.model || "");
      formData.append("image", values.image || "");
      formData.append("whatsappId", selectedQueueIds);

      if(selectedTagIds.length != 0) {
        formData.append("selectedTagIds", selectedTagIds);
      }

      try {
        await api.post("/bulkMessage/send", formData);
        handleClose();
        actions.setSubmitting(false);
        toast.success("Mensagem enviada com sucesso");

        // console.log(formData);
      } catch (error) {
        toastError(error);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handlePreview = async (values) => {
    // Lógica para gerar o preview da mensagem
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setIsFileUploaded(true); // Define como true se um arquivo for carregado
      setFieldValue("image", file);
      setImage(file);
      event.target.value = ""; // Limpa o valor do input de arquivo para garantir que o evento onChange seja disparado novamente
    } else {
      setIsFileUploaded(false); // Define como false se o arquivo for removido
      setFieldValue("image", null);
      setImage(null);
    }
  };

  const handleAttachButtonClick = (event, setFieldValue) => {
    if (isFileUploaded) {
      event.preventDefault(); // Impede o comportamento padrão do botão apenas se houver um arquivo carregado

      setIsFileUploaded(false); // Define isFileUploaded como false para indicar que não há mais arquivo
      setFieldValue("image", null); // Limpa o valor do campo de imagem
      setImage(null); // Limpa o estado da imagem
      setContact((prevState) => ({
        ...prevState,
        image: null,
      }));
    }
  };

  const handlePhonesTxtArea = (event, setFieldValue) => {
    // Obtém o valor atual do texto no TextArea
    let currentValue = event.target.value;

    // Substitui todos os espaços e quebras de linha por vírgulas
    currentValue = currentValue.replace(/\s+/g, ",");

    // Atualiza o estado do formulário com o novo valor
    setFieldValue("phone", currentValue);
    setPhoneData(currentValue);
  };

  const handleImportCSV = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        // Parse do conteúdo do arquivo CSV
        const rows = content.split("\n");
        const numbers = rows
          .map((row) => {
            const columns = row.split(";"); // Dividindo por ';'
            const phoneNumber = columns[1]
              ? columns[1].replace(/"/g, "").trim()
              : null; // Remover aspas e espaços do número
            // Verificar se o phoneNumber é um número
            if (/^\d+$/.test(phoneNumber)) {
              return phoneNumber;
            } else {
              return null; // Se não for número, retornar null para ser filtrado posteriormente
            }
          })
          .filter((number) => !!number); // Remover valores vazios ou nulos

        const phoneString = numbers;
        // Atualizando o estado do campo de telefone com a string de números de telefone
        setPhone(phoneString);
        // Atualizando o estado do textarea com a string de números de telefone
        setPhoneData(phoneString);
      };
      reader.readAsText(file);
    }
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <MainHeader>
        <Title>Envio de Mensagem</Title>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Formik
          initialValues={contact}
          enableReinitialize={true}
          validationSchema={ContactSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveContact(values, actions);
            }, 400);
          }}
        >
          {({ errors, touched, isSubmitting, setFieldValue }) => (
            <Form>
              <div>
                <SelectConection
                  selectedQueueIds={selectedQueueIds}
                  onChange={(selectedIds) => setSelectedQueueIds(selectedIds)}
                />
                <Field
                  as={TextField}
                  label="Modelo"
                  type="model"
                  multiline
                  minRows={10}
                  fullWidth
                  name="model"
                  error={touched.model && Boolean(errors.model)}
                  helperText={touched.model && errors.model}
                  variant="outlined"
                  margin="dense"
                />
                <Field
                  as={TextField}
                  label="Dados"
                  multiline
                  minRows={5}
                  fullWidth
                  name="phone"
                  value={phoneData}
                  onChange={(event) =>
                    handlePhonesTxtArea(event, setFieldValue)
                  }
                  variant="outlined"
                  margin="dense"
                />

                <TagSelect
                  selectedTagIds={selectedTagIds}
                  onChange={setSelectedTagIds}
                  title="Etiquetas"
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    columnGap: "20px",
                    paddingTop: "20px",
                  }}
                >
                  {/* Botão para anexar arquivo de imagem */}
                  <label
                    htmlFor="image"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      id="image"
                      name="medias"
                      type="file"
                      onChange={(event) =>
                        handleFileChange(event, setFieldValue)
                      }
                      style={{ display: "none" }}
                    />
                    <Button
                      variant="contained"
                      color={isFileUploaded ? "secondary" : "primary"}
                      component="span"
                      onClick={(event) =>
                        handleAttachButtonClick(event, setFieldValue)
                      }
                      style={{ alignSelf: "center" }}
                    >
                      {isFileUploaded ? "Remover Arquivo" : "Anexar Arquivo"}
                    </Button>
                  </label>

                  {/* Botão para importar o arquivo CSV */}
                  <label
                    htmlFor="csv"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      id="csv"
                      type="file"
                      accept=".csv"
                      onChange={handleImportCSV}
                      style={{ display: "none" }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{ alignSelf: "center" }}
                    >
                      Importar Contatos CSV
                      <ImportContacts style={{ marginLeft: "10px" }} />
                    </Button>
                  </label>

                  {/* Botão para exportar uma template de exemplo CSV */}
                  <CSVLink
                    className={classes.csvbtn}
                    separator=";"
                    filename={"template.csv"}
                    data={templateData}
                  >
                    <Button variant="contained" color="default">
                      Baixar Template CSV
                    </Button>
                  </CSVLink>
                </div>

                <LinearBuffer progressMessage={progressMessage} />
              </div>
              <div>
                {/* <Button
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitting}
                  onClick={() => handlePreview(contact)}
                  className={classes.btnWrapper}
                >
                  Mostrar preview
                    </Button> */}
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  Enviar Mensagem
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <div>{message.length > 0 && <PreviewMessage message={message} />}</div>
      </Paper>
    </MainContainer>
  );
};

export default Contacts;
