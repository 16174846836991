import React, { useState, useEffect, useReducer, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import api from "../../services/api";
import OperatorTable from "../../components/TableWithPagination";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    display: "flex",
  },
  card: {
    padding: "20px",
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  searchBar: {
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    backgroundColor: "#fff",
    padding: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
}));

const useStatusStyles = makeStyles({
  online: {
    color: "green",
    fontWeight: "600",
    fontSize: "0.8rem",
  },
  offline: {
    color: "red",
    fontWeight: "600",
    fontSize: "0.8rem",
  },
});

const Status = ({ isOnline }) => {
  const classes = useStatusStyles();
  return (
    <span className={isOnline ? classes.online : classes.offline}>
      {isOnline ? "Online" : "Offline"}
    </span>
  );
};

const Reports = () => {
  const classes = useStyles();

  const history = useHistory();

  const [cardsData, setCardsData] = useState([]);
  const [agentsData, setAgentsData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchCardsData = async () => {
      try {
        const response = await api.get("/reports/cards");
        console.log(response);
        setCardsData(response.data);
      } catch (error) {
        console.error("Erro ao processar o pedido:", error);
      }
    };

    fetchCardsData();
  }, []);

  useEffect(() => {
    const fetchAgentsData = async () => {
      try {
        const response = await api.get("/reports/agents-list");
        setAgentsData(response.data);
        setFilteredData(response.data);
      } catch (error) {
        console.error("Erro ao processar o pedido:", error);
      }
    };

    fetchAgentsData();
  }, []);

  useEffect(() => {
    const filterAndSortData = () => {
      let filtered = agentsData;
      if (searchQuery.length >= 3) {
        filtered = agentsData.filter((agent) =>
          Object.values(agent).some((value) =>
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
          )
        );
      }
      // Ordenar os dados pelo nome em ordem ascendente
      filtered = filtered.sort((a, b) => a.nome.localeCompare(b.nome));
      setFilteredData(filtered);
    };

    filterAndSortData();
  }, [searchQuery, agentsData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (row) => {
    history.push(`/user-tickets-list/${row.id}`);
  };

  const columns = [
    { field: "nome", headerName: "Nome", minWidth: 150 },
    {
      field: "isOnline",
      headerName: "Status",
      minWidth: 100,
      render: (value) => <Status isOnline={value} />,
    },
    { field: "ticketsAbertos", headerName: "Em Aberto", minWidth: 150 },
    { field: "ticketsFechados", headerName: "Finalizadas", minWidth: 150 },
  ];

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Paper className={`${classes.card} ${classes.leftCard}`}>
            <Typography variant="h5" component="h2">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Box>
                  <h5>Conversas</h5>
                </Box>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="body1">Abertas</Typography>
                    <Typography variant="body2">{cardsData.abertas}</Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="body1">Sem Atendente</Typography>
                    <Typography variant="body2">
                      {cardsData.naoAtendidas}
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="body1">Sem Fila</Typography>
                    <Typography variant="body2">
                      {cardsData.naoAtribuida}
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="body1">Pendentes</Typography>
                    <Typography variant="body2">
                      {cardsData.pendente}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={`${classes.card} ${classes.rightCard}`}>
            <Typography variant="h5" component="h2">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Box>
                  <h5>Operadores</h5>
                </Box>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="body1">Online</Typography>
                    <Typography variant="body2">{cardsData.onlines}</Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="body1">Offline</Typography>
                    <Typography variant="body2">
                      {cardsData.offlines}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.tableContainer}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.header}
            >
              <Grid item>
                <Typography variant="h6">Conversas por Operador</Typography>
              </Grid>
              <Grid item>
                <TextField
                  className={classes.searchBar}
                  label="Pesquisar"
                  variant="outlined"
                  size="small"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Grid>
            </Grid>
            <OperatorTable
              columns={columns}
              data={filteredData}
              rowsPerPageOptions={[10, 25, 50]}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              count={filteredData.length}
              onRowClick={handleRowClick}
              customizeColumns={true}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Reports;
