import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import api from "../../services/api";
import {IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modalRoot: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999,
  },
  modalContent: {
    width: 400,
    backgroundColor: "#fff",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    maxHeight: "80vh",
    overflowY: "auto",
    overflowX: "hidden",
  },
  contactRow: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    columnGap: "10px",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  selectedContact: {
    backgroundColor: "#f0f0f0",
  },
  checkIcon: {
    marginLeft: "auto",
    color: "#00a152",
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
}));

const ContactListShareModal = ({ onClose, onSelectContacts }) => {
  const classes = useStyles();
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { searchParam },
          });
          setContacts(data.contacts);
          setLoading(false);
        } catch (err) {
          console.error("Error fetching contacts:", err);
          setLoading(false);
        }
      };
      fetchContacts();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam]);

  const handleContactClick = (contact) => {
    if (isSelected(contact)) {
      setSelectedContacts(
        selectedContacts.filter(
          (selectedContact) => selectedContact.id !== contact.id
        )
      );
    } else {
      setSelectedContacts([...selectedContacts, contact]);
    }
  };

  const isSelected = (contact) => {
    return selectedContacts.some(
      (selectedContact) => selectedContact.id === contact.id
    );
  };

  const handleConfirmSelection = () => {
    onSelectContacts(selectedContacts);
    console.log("handlenomodal", selectedContacts);
  };

  return (
    <div className={classes.modalRoot}>
      <Paper
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={onClose} aria-label="Fechar">
            <CloseIcon />
          </IconButton>
        </div>
        <TextField
          label="Pesquisar"
          variant="outlined"
          fullWidth
          value={searchParam}
          onChange={(e) => setSearchParam(e.target.value)}
        />
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {contacts.map((contact) => (
              <TableRow
                key={contact.id}
                className={`${classes.contactRow} ${
                  isSelected(contact) && classes.selectedContact
                }`}
                onClick={() => handleContactClick(contact)}
              >
                <Avatar src={contact.profilePicUrl} />
                <span>{contact.name}</span>
                {isSelected(contact) && (
                  <CheckIcon className={classes.checkIcon} />
                )}
              </TableRow>
            ))}
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirmSelection}
              >
                Confirmar
              </Button>
            </div>
          </>
        )}
      </Paper>
    </div>
  );
};

export default ContactListShareModal;
